import actionTypes from '../../constants/actions/importarVerracosExcel/importarVerracosExcel'

export function submitImportarVerracosExcel (value) {
  return {
    type: actionTypes.SUBMIT_IMPORTAR_VERRACOS_EXCEL,
    value
  }
}

export function submitImportarVerracosExcelSuccess (listImportarVerracosExcel) {
  return {
    type: actionTypes.SUBMIT_IMPORTAR_VERRACOS_EXCEL_SUCCESS,
    listImportarVerracosExcel
  }
}

export function abrirModalImportarVerracosExcel () {
  return {
    type: actionTypes.ABRIR_MODAL_IMPORTAR_VERRACOS_EXCEL
  }
}

export function cerrarModalImportarVerracosExcel () {
  return {
    type: actionTypes.CERRAR_MODAL_IMPORTAR_VERRACOS_EXCEL
  }
}

export function subirArchivosImportarVerracosExcel (archivoExcel) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_IMPORTAR_VERRACOS_EXCEL,
    archivoExcel
  }
}

export function descargarPlantillaExcelVerracosExcel () {
  return {
    type: actionTypes.DESCARGAR_PLANTILLA_EXCEL_VERRACOS_EXCEL
  }
}

export default {
  submitImportarVerracosExcel,
  abrirModalImportarVerracosExcel,
  cerrarModalImportarVerracosExcel,
  subirArchivosImportarVerracosExcel,
  submitImportarVerracosExcelSuccess,
  descargarPlantillaExcelVerracosExcel
}