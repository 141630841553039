import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from 'react-select'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import Dropzone from 'react-dropzone'
import { Unarchive } from '@material-ui/icons'
import './ImportarVerracosExcel.scss'

class ImportarVerracosExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivos: '',
      archivoCargado: false
    }
  }

  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        this.props.actions.subirArchivosImportarVerracosExcel({name: archivo.name, file: reader.result})
        this.setState({archivos: {name: archivo.name, file: reader.result}})
        this.setState({archivoCargado: true})
      }
      reader.readAsDataURL(archivo)
    })
  }

  submitImportarVerracosExcel () {
    this.props.actions.submitImportarVerracosExcel(this.state.archivos)
    this.setState({archivoCargado: false})
  }

  cerrarModalImportarVerracosExcel () {
    this.props.actions.cerrarModalImportarVerracosExcel()
    this.setState({archivos: ''})
    this.setState({archivoCargado: false})
  }

  descargarPlantillaExcelVerracosExcel () {
    this.props.actions.descargarPlantillaExcelVerracosExcel()
  }

  render () {
    const {
      t, handleSubmit, showModalImportarVerracosExcel, archivoExcel, listImportarVerracosExcel
      } = this.props

    if(!showModalImportarVerracosExcel) return null

    let errorImportar = listImportarVerracosExcel.filter(item => item.insertado===false)
    return (
      <Modal show={showModalImportarVerracosExcel} onHide={() => this.cerrarModalImportarVerracosExcel()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-carga-index">
      <form>
        <Modal.Header closeButton>
          <Modal.Title>
          {t('IMPORTAR_VERRACOS_EXCEL.TITLE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="archivos-carga-index">
              <Dropzone
                accept=".xls"
                onDrop={this.onDrop.bind(this)}
                uploadMultiple={true}
                maxFiles={null}
                // maxSize={200000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}  className="custom-file-label">
                    <input {...getInputProps()} />
                    <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                  </div>
                )}
              </Dropzone>
            </div>
            {
              Object.keys(archivoExcel).length > 0 ? (
                <p className="nombre-archivo-subido"><Unarchive />{archivoExcel.name}</p>
              ) : null
            }            
            {
              Object.keys(errorImportar).length > 0 ? (
                <div className="resultado-importar-verracos error-importar">
                  <ul>
                    <li>{t('IMPORTAR_VERRACOS_EXCEL.ERROR')}</li>
                  </ul>
                </div>
              ) : Object.keys(listImportarVerracosExcel).length > 0 ? (
                <div className="resultado-importar-verracos">                
                  <ul>                                          
                    <li>{t('IMPORTAR_VERRACOS_EXCEL.DOCUMENTO_SUBIDO')}</li>                      
                  </ul>                                      
                </div>
              ) : null
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>          
          <Button type="button" onClick={() => this.descargarPlantillaExcelVerracosExcel()} className="btn btn-primary">{t('IMPORTAR_VERRACOS_EXCEL.DESCARGAR_EJEMPLO_EXCEL')}</Button>
          {
            Object.keys(listImportarVerracosExcel).length === 0 ? (
              <Button type="button" onClick={() => this.submitImportarVerracosExcel()} className="btn btn-primary">{t('CARGA_INDEX.ACEPTAR')}</Button>
            ) : null
          }
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalImportarVerracosExcel()}>{t('CARGA_INDEX.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ImportarVerracosExcel',
})(ImportarVerracosExcel))