import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row, Col } from 'react-bootstrap'
import { Warning, Clear , Check } from '@material-ui/icons'
import Loader from '../comun/loader/Loader'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import ModalTrazabilidadMaterialPage from '../../containers/trazabilidadMaterial/ModalTrazabilidadMaterialPage'
import {modulos as modulosConstant} from '../../constants/modulos'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import {estadoMaquinaDilucion, translateEstadoMaquinaDilucion} from '../../constants/maquinas'
import InputText from '../comun/form/InputText'

class ModalDilucionAnalisisExtraccion extends Component {
  constructor (props) {
    super()
    this.state = {
      diluido: false,
      dilucionEliminada: false
    }
    this.intercambiarCheckDiluido = this.intercambiarCheckDiluido.bind(this)
    this.intercambiarCheckDilucionEliminada = this.intercambiarCheckDilucionEliminada.bind(this)
    this.prepararMaquinaDilucion = this.prepararMaquinaDilucion.bind(this)
    this.cancelarMaquinaDilucion = this.cancelarMaquinaDilucion.bind(this)
    this.handleOpenModalTrazabilidadMaterial = this.handleOpenModalTrazabilidadMaterial.bind(this)
    this.guardarModalDilucionAnalisisExtraccion = this.guardarModalDilucionAnalisisExtraccion.bind(this)
  }

  intercambiarCheckDiluido (value) {
    this.setState({diluido: value})
  }

  intercambiarCheckDilucionEliminada (value) {
    let newState = {
      dilucionEliminada: value,
      diluido: value
    }
    this.setState(newState)

    if (value === true) {
      this.props.change('diluido', true)
    } else {
      this.props.change('diluido', false)
    }
  }
  prepararMaquinaDilucion (data) {
    if (!data.idTipoDiluyente || !data.idTipoDiluyente.value) {
      this.props.actions.yesNoModal('diluyenteRequerido')
    } else if (!data.idMaquina || !data.idMaquina.value) {
      this.props.actions.yesNoModal('maquinaRequerida')
    } else {
      return new Promise((resolve, reject) => {
        this.props.actions.prepararMaquinaDilucionAnalisisExtraccion(data, this.props.match.params.idAnalisisExtraccion || null, this.props.history, () => {
          resolve()
        }, reject)
      })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })
    }
  }

  cancelarMaquinaDilucion () {
    this.props.actions.cancelarMaquinaDilucionAnalisisExtraccion()
  }

  handleOpenModalTrazabilidadMaterial () {
    if (this.props.formAnalisisExtraccion.tipoDosis) {
      this.props.actions.openModalTrazabilidadMaterial({
        idTipoDosis: this.props.formAnalisisExtraccion.tipoDosis.value,
        idCategoria: categoriasEscandallos.ANALISIS_DILUCION,
        idAnalisis: this.props.data.idAnalisis || null,
        ultimaModificacion: this.props.data ? this.props.data.ultimaModificacion : false,
        disabled: this.props.data ? this.props.data.envasado : false,
        showStockColumns: !this.props.data.diluido,
        utilizarLotesPreferidosDelUsuario: this.props.utilizarLotesPreferidosDelUsuario
      })
    }
  }

  guardarModalDilucionAnalisisExtraccion (data) {
    return new Promise((resolve, reject) => {
      this.props.actions.guardarModalDilucionAnalisisExtraccion(data, this.props.match.params.idAnalisisExtraccion || null, this.props.history, () => {
        resolve()
      }, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  diluyenteSeleccionado(value){
    console.log(value)
    console.log(this)
    console.log(this.props.formModalDilucionAnalisisExtraccion)
  }


  componentDidMount () {
    this.props.actions.comboMaquina(2)
    this.props.actions.comboOperarioPredeterminado()
    this.props.actions.fetchComboTipoDiluyente()

    if (this.props.data && this.props.data.diluido) {
      this.setState({diluido: this.props.data.diluido})
    }
    if (this.props.data && this.props.data.dilucionEliminada) {
      this.setState({dilucionEliminada: this.props.data.dilucionEliminada})
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formModalDilucionAnalisisExtraccion && this.props.formModalDilucionAnalisisExtraccion.diluido !== this.state.diluido) {
      this.setState({diluido: this.props.formModalDilucionAnalisisExtraccion.diluido})
    }
    if (this.props.showModalDilucionAnalisisExtraccion && this.props.showModalDilucionAnalisisExtraccion !== prevProps.showModalDilucionAnalisisExtraccion) {
      if (this.props.data && this.props.data.diluido !== this.state.diluido) {
        this.setState({diluido: this.props.data.diluido})
      }
      if (this.props.data && this.props.data.dilucionEliminada !== this.state.dilucionEliminada) {
        this.setState({dilucionEliminada: this.props.data.dilucionEliminada})
      }
    } else if (!this.props.showModalDilucionAnalisisExtraccion && this.props.showModalDilucionAnalisisExtraccion !== prevProps.showModalDilucionAnalisisExtraccion) {
      this.setState({diluido: false, dilucionEliminada: false})
    }
    //console.log(this.props.formModalDilucionAnalisisExtraccion.idTipoDiluyente.MinGradosBrix)
  }

  render () {
    const {
      t, handleSubmit, showModalDilucionAnalisisExtraccion, loadTrazabilidadMaterialModal = true, stockInsuficiente,
      submitting, codigoEstadoMaquinaDilucionAnalisisExtraccion, data: {envasado, dilucion}, auth: {modulos},
      combos: {comboMaquina, comboOperarioPredeterminado, comboTipoDiluyente},
      actions: {cerrarModalDilucionAnalisisExtraccion}
    } = this.props
    const tKey = 'ANALISIS_EXTRACCIONES.DILUCION.'

    const diluyendo = false
    const check_pool = (typeof(this.props.data.idPool)!=='undefined' && this.props.data.idPool!==null)//es un pool
    // const diluyendo = ( codigoEstadoMaquinaDilucionAnalisisExtraccion !== '' &&
    //   codigoEstadoMaquinaDilucionAnalisisExtraccion !== estadoMaquinaDilucion['EN_ESPERA_RETIRAR_RECIPIENTE'] &&
    //   codigoEstadoMaquinaDilucionAnalisisExtraccion !== estadoMaquinaDilucion['FINALIZADO'] &&
    //   codigoEstadoMaquinaDilucionAnalisisExtraccion !== estadoMaquinaDilucion['NO_PREPARADA']
    // )

    return (
      <Modal show={showModalDilucionAnalisisExtraccion} onHide={cerrarModalDilucionAnalisisExtraccion} backdrop="static">
        <form className="form-dilucion">
        <Modal.Header closeButton={!diluyendo}>
          <Modal.Title>{t(tKey + 'TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && loadTrazabilidadMaterialModal && (
            <ModalTrazabilidadMaterialPage />
          )}
            <Row>
              <Field
                colSm={4}
                id="numeroDosis"
                name="numeroDosis"
                controlLabel={t(tKey + 'FORM.NUMERO_DOSIS')}
                component={InputNumerical}
                numDecimales={0}
                disabled={true}
              />
              <Field
                colSm={4}
                id="volumenExtraccion"
                name="volumenExtraccion"
                controlLabel={t(tKey + 'FORM.VOL_EYACULADO')}
                component={InputNumerical}
                numDecimales={2}
                disabled={true}
              />
              <Field
                colSm={4}
                id="volumenDiluyente"
                name="volumenDiluyente"
                controlLabel={t(tKey + 'FORM.VOL_DILUYENTE')}
                component={InputNumerical}
                numDecimales={2}
                disabled={true}
              />
            </Row>
            <Row>
              <Field
                colSm={8}
                id="idTipoDiluyente"
                name="idTipoDiluyente"
                controlLabel={t(tKey + 'FORM.TIPO_DILUYENTE')}
                component={InputSelect}
                options={comboTipoDiluyente}
                valueKey="value"
                labelKey="label"
                isClearable={true}
                disabled={diluyendo || envasado || this.state.dilucionEliminada}
                onInputChange = {(value) => this.diluyenteSeleccionado(value)}
              />
              <div className="clearfix"></div>
              <Col sm={8}>
                <p>{t(tKey + 'FORM.AVISO_DILUYENTE')}</p>
              </Col>
            </Row>
              <Row style={{display: "flex", alignItems: "center" }}>
                <Field
                  colSm={5}
                  id="LecturaRefractometro"
                  name="LecturaRefractometro"
                  controlLabel={t('ANALISIS_EXTRACCIONES.DILUCION.FORM.REFRACTOMETRO')}
                  component={InputNumerical}
                  style={{ Margin:"0" }}
                />
                <Button 
                  type="button"
                  className='btn btn-primary'
                  style={{ visibility:'collapse' }}
                >{t('ANALISIS_EXTRACCIONES.DILUCION.FORM.OBTENER')}</Button>
                <Col sm={2}>
                {this.props.formModalDilucionAnalisisExtraccion?.LecturaRefractometro != undefined &&
                this.props.formModalDilucionAnalisisExtraccion.LecturaRefractometro != undefined &&
                this.props.formModalDilucionAnalisisExtraccion.idTipoDiluyente.MinGradosBrix != undefined && 
                this.props.formModalDilucionAnalisisExtraccion.idTipoDiluyente.MaxGradosBrix != undefined && 
                parseFloat(this.props.formModalDilucionAnalisisExtraccion.LecturaRefractometro) >= parseFloat(this.props.formModalDilucionAnalisisExtraccion.idTipoDiluyente.MinGradosBrix) &&
                parseFloat(this.props.formModalDilucionAnalisisExtraccion.LecturaRefractometro) <= parseFloat(this.props.formModalDilucionAnalisisExtraccion.idTipoDiluyente.MaxGradosBrix) ? (
                  <Check style={{ color: 'rgb(0, 255, 0)' }}/>
                ) : (
                  this.props.formModalDilucionAnalisisExtraccion?.LecturaRefractometro != undefined &&
                  this.props.formModalDilucionAnalisisExtraccion?.LecturaRefractometro != '' && 
                  this.props.formModalDilucionAnalisisExtraccion.idTipoDiluyente.MinGradosBrix != undefined && 
                  this.props.formModalDilucionAnalisisExtraccion.idTipoDiluyente.MaxGradosBrix != undefined && <Clear className="text-danger"/>
                )}
                </Col>
                <Field
                  colSm={5}
                  id="Temperatura"
                  name="Temperatura"
                  controlLabel={t('ANALISIS_EXTRACCIONES.DILUCION.FORM.TEMPERATURA')}
                  component={InputNumerical}
                  numDecimales={0}
                />
              </Row>
              <Row>
              <Field
                colSm={6}
                id="idMaquina"
                name="idMaquina"
                controlLabel={t(tKey + 'FORM.MAQUINA')}
                component={InputSelect}
                options={comboMaquina}
                valueKey="value"
                labelKey="label"
                isClearable={true}
                disabled={diluyendo || envasado || this.state.dilucionEliminada}
              />
              <Field
                colSm={6}
                id="idOperario"
                name="idOperario"
                controlLabel={t(tKey + 'FORM.OPERARIO')}
                component={InputSelect}
                options={comboOperarioPredeterminado}
                valueKey="value"
                labelKey="label"
                disabled={diluyendo || envasado || this.state.dilucionEliminada}
              />
              </Row>
              <Row>
                <Field
                  colSm={6}
                  id="LoteDilucion"
                  name="LoteDilucion"
                  controlLabel={t('ANALISIS_EXTRACCIONES.DILUCION.FORM.LOTE_DILUCION')}
                  component={InputText}
                  numDecimales={0}
                />
              </Row>
              <Row>
              <Field
                id="diluido"
                name="diluido"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t(tKey + 'FORM.DILUIDO')}
                onInputChange={(value) => this.intercambiarCheckDiluido(value)}
                disabled={diluyendo || envasado || this.state.dilucionEliminada || check_pool}
              />
              </Row>
              <Row>
              <Field
                id="dilucionEliminada"
                name="dilucionEliminada"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t(tKey + 'FORM.DILUCION_ELIMINADA')}
                onInputChange={(value) => this.intercambiarCheckDilucionEliminada(value)}
                disabled={diluyendo || envasado}
              />
              </Row>
              <Row>
              <Field
                colSm={12}
                id="observacionesDilucion"
                name="observacionesDilucion"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
                disabled={diluyendo}
              />
              </Row>
              {this.state.dilucionEliminada && (
                <Row>
                  <Field
                    colSm={12}
                    id="observacionesDilucionEliminada"
                    name="observacionesDilucionEliminada"
                    controlLabel={t(tKey + 'FORM.OBSERVACIONES_ELIMINADO')}
                    component={InputTextArea}
                    disabled={diluyendo || envasado}
                  />
                </Row>
              )}
        </Modal.Body>
        <Modal.Footer>
          <Row className="row-flex">
            <div className="col-flex text-left bold">
              {diluyendo && (<Loader />)}
              {codigoEstadoMaquinaDilucionAnalisisExtraccion ? t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaDilucion[codigoEstadoMaquinaDilucionAnalisisExtraccion]) : ''}
            </div>
            <Col sm={diluyendo ? 8 : 7}>
              {modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                <Button type="button" className="btn btn-primary" disabled={diluyendo || this.state.dilucionEliminada || submitting} onClick={!diluyendo && !this.state.dilucionEliminada && !submitting ? this.handleOpenModalTrazabilidadMaterial : null}>
                  {stockInsuficiente && (<span className='help-block white'><Warning /></span>)} {t(tKey + 'BUTTONS.TRAZABILIDAD')}
                </Button>
              )}
              <Button
                type="button"
                className="btn btn-primary"
                disabled={this.state.diluido || envasado || this.state.dilucionEliminada || submitting}
                onClick={!this.state.diluido && !envasado && !this.state.dilucionEliminada && !submitting ? (!diluyendo ? handleSubmit(this.prepararMaquinaDilucion) : this.cancelarMaquinaDilucion) : null}
              >
                {diluyendo ? t(tKey + 'BUTTONS.PARAR_DILUCION') : t(tKey + 'BUTTONS.DILUIR')}
              </Button>
              <Button type="button" className="btn btn-primary" disabled={diluyendo || envasado || submitting || check_pool} onClick={(!diluyendo && !envasado && !submitting) ? handleSubmit(this.guardarModalDilucionAnalisisExtraccion.bind(this)) : null}>{t(tKey + 'BUTTONS.GUARDAR')}</Button>
              <Button type="button" className="btn btn-white" disabled={diluyendo || submitting} onClick={(!diluyendo && !submitting) ? cerrarModalDilucionAnalisisExtraccion : null}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
            </Col>
          </Row>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalDilucionAnalisisExtraccion',
})(ModalDilucionAnalisisExtraccion))