import {call, put, takeLatest, all, select, race, take} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { initialize, getFormValues, change } from 'redux-form';
import i18n from '../../i18n';

import {modulos as modulosConstant} from '../../constants/modulos'
import actionTypes from '../../constants/actions/envasado/envasado'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import {estadoMaquinaEnvasado, translateEstadoMaquinaEnvasado} from '../../constants/maquinas'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta, openMensajeAlertaCustomText} from '../../actions/common'
import {
  fetchEnvasadoSuccess, fetchFiltrarEnvasadoSuccess,crearCsvEnvasadoSuccess, imprimirPdfEnvasadoSuccess, updateTotalUtilizadoDetalleEnvasadoSuccess,
  abrirDetallesEnvasado as abrirDetallesEnvasadoAction, abrirDetallesEnvasadoSuccess, cerrarDetallesEnvasadoSuccess, deleteEnvasadoMultiDosisSuccess,
  abrirModalEnvasadoMultiDosisSuccess, guardarModalEnvasadoMultiDosisSuccess, cerrarModalEnvasadoMultiDosisSuccess,
  actualizarEnvasadoMultiDosisSuccess, actualizarEstadoMaquinaEnvasado
} from '../../actions/envasado/envasado'
import { comprobarStockTrazabilidad, comprobarStockTrazabilidadSuccess, openModalTrazabilidadMaterial, resetUtilizarLotesPreferidosDelUsuarioSuccess
} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import getEnvasadoList from '../../services/envasado/getEnvasadoList'
import getEnvasado from '../../services/envasado/getEnvasado'
import editEnvasado from '../../services/envasado/editEnvasado'
import prepararMaquinaEnvasadoService from '../../services/envasado/prepararMaquinaEnvasado'
import comprobarEstadoMaquinaEnvasadoService from '../../services/envasado/comprobarEstadoMaquinaEnvasado'
import getFiltrarEnvasado from '../../services/envasado/getFiltrarEnvasado'
import getCrearCsv from '../../services/envasado/getCrearCsv'
import getImprimirPdfEnvasado from '../../services/envasado/getImprimirPdfEnvasado'
import { obtenerNotificaconesUsuarioSuccess } from '../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../services/notificaciones/getNotificaciones'
import getFiltrarConectividad from '../../services/conectividad/getFiltrarConectividad'
import printronixService from '../../services/comun/printronix'
import deleteSeleccionDosisService from '../../services/envasado/deleteSeleccionDosis'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import {
  comboTipoDosis as comboTipoDosisSaga, comboMaquina as comboMaquinaSaga
} from '../combos/combos'
import {comboMaquina, comboNombreEscandallo} from '../../actions/combos/combos'

export function * fetchEnvasado () {
  try {
    const list = yield call(getEnvasadoList, null)
    yield put(fetchEnvasadoSuccess(list))
  } catch (error) {}
}
export function * watchFetchEnvasado () {
  yield takeLatest(actionTypes.FETCH_ENVASADO, fetchEnvasado)
}

export function * fetchFiltrarEnvasado ({values}) {
  try {
    const auth = yield select(state => state.auth)
    const list = yield call(getFiltrarEnvasado, values, auth.token)
    yield put(fetchEnvasadoSuccess(list))
    yield put(fetchFiltrarEnvasadoSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarEnvasado () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_ENVASADO, fetchFiltrarEnvasado)
}

export function * crearCsvEnvasado ({list}) {
  try {
    const auth = yield select(state => state.auth)
    const datosExcelEnvasado = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvEnvasadoSuccess(datosExcelEnvasado))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvEnvasado () {
  yield takeLatest(actionTypes.CREATE_CSV_ENVASADO, crearCsvEnvasado)
}
export function * imprimirPdfEnvasado ({list}) {
  try {
    const auth = yield select(state => state.auth)
    const datosPdfEnvasado = yield call(getImprimirPdfEnvasado, list, auth.token)
    yield put(imprimirPdfEnvasadoSuccess(datosPdfEnvasado))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfEnvasado () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_ENVASADO, imprimirPdfEnvasado)
}

export function * abrirDetallesEnvasado ({idEnvasado, callback}) {
  try {
    // yield call(comboMaquinaSaga(1))
    yield put(comboMaquina(1))
    const data = yield call(getEnvasado, idEnvasado, null)    
    data.codVerracoPool = data.codVerraco || data.nombrePool

    data.totalDisponible = 0
    if (data.dosisReales && data.volumenTipoDosis) {
      data.totalDisponible = parseInt(data.dosisReales, 10) * parseFloat(data.volumenTipoDosis)
    }

    data.totalUtilizado = 0
    if (data.multiDosis && data.multiDosis.length > 0) {
      const comboMaquina = yield select(state => state.combos.comboMaquina)
      data.multiDosis.forEach((dosis) => {
        dosis.nombreMaquina = comboMaquina.find((maquina) => {
          return maquina.value === dosis.idMaquina
        })
        dosis.nombreMaquina = dosis.nombreMaquina ? dosis.nombreMaquina.label : ''
        dosis.tipoDosis = dosis.nombreTipoDosis
        dosis.volumen = parseFloat(dosis.volumenTipoDosis) * parseInt(dosis.numeroDosis, 10)
        dosis.estadoInicialEnvasado = dosis.envasado
        data.totalUtilizado += dosis.volumen

        data.loteBolsas = dosis.nombreMaquina ? dosis.loteBolsas : ''
      })
    }

    yield put(abrirDetallesEnvasadoSuccess(data))
    yield put(initialize('ModalEnvasado', {
      idEnvasado: data.idEnvasado,
      codVerracoPool: data.codVerracoPool,
      nombreRaza: data.nombreRaza,
      nombreLinea: data.nombreLinea,
      fechaCaducidad: data.fechaCaducidad,
      fechaExtraccion: data.fechaExtraccion,
      observacion:data.observacion,
      index: data.index,
      nivelGenetico: data.nivelGenetico
    }))

    if (callback) yield call(callback, data)
  } catch (error) {}
}
export function * watchAbrirDetallesEnvasado () {
  yield takeLatest(actionTypes.ABRIR_DETALLES_ENVASADO, abrirDetallesEnvasado)
}

export function * cerrarDetallesEnvasado ({idEnvasado}) {
  try {
    yield put(cerrarDetallesEnvasadoSuccess())
  } catch (error) {}
}
export function * watchCerrarDetallesEnvasado () {
  yield takeLatest(actionTypes.CERRAR_DETALLES_ENVASADO, cerrarDetallesEnvasado)
}

export function * editarEnvasado ({values}) {
  try {
    const state = yield select(state => state)
    if (state.envasado.data.multiDosis.length < 1) {
      yield call(yesNoModalSaga, {modalType: 'envasadoMultiDosisRequerido'})
    } else {
      let updatedValues = {...values, multiDosis: state.envasado.data.multiDosis}

      yield call(editEnvasado, updatedValues, state.auth.token)
      yield put(cerrarDetallesEnvasadoSuccess())
      const auth = yield select(state => state.auth)
      const val = yield select(state => state.envasado.filtros)
      const list = yield call(getFiltrarEnvasado, val, auth.token)
      yield put(fetchEnvasadoSuccess(list))
      yield put(fetchFiltrarEnvasadoSuccess(val))

      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {}
}
export function * watchEditarEnvasado () {
  yield takeLatest(actionTypes.EDITAR_ENVASADO, editarEnvasado)
}

export function * deleteEnvasadoMultiDosis ({row}) {
  try {
    if (row && !row.editable) {
      yield call(yesNoModalSaga, {modalType: 'envasadoNoEliminable'})
    } else {
      const state = yield select(state => state)
      let list = state.envasado.data.multiDosis.filter((currentRow) => {
        return currentRow.idEnvasadoMultiDosis !== row.idEnvasadoMultiDosis
      })
      yield put(deleteEnvasadoMultiDosisSuccess(list))
      yield call(updateTotalUtilizadoDetalleEnvasado)
    }
  } catch (error) {}
}
export function * watchDeleteEnvasadoMultiDosis () {
  yield takeLatest(actionTypes.DELETE_ENVASADO_MULTI_DOSIS, deleteEnvasadoMultiDosis)
}

export function * deleteSeleccionEnvasadoMultiDosis ({list}) {
  try {
    const state = yield select(state => state)
    let hayEnvadadosNoEditables = false
    let confirmed = true
    let newList = state.envasado.data.multiDosis.filter((row) => {
      return !list.some((deletedRow) => {
        if (deletedRow.idEnvasadoMultiDosis === row.idEnvasadoMultiDosis) {
          if (!row.editable || row.trazabilidad) hayEnvadadosNoEditables = true
          return row.editable
        }
        return false
      })
    })
    if (hayEnvadadosNoEditables) {
      confirmed = yield call(yesNoModalSaga, {modalType: 'envasadosNoEliminables'})
    }
    if (confirmed) {
      yield put(deleteEnvasadoMultiDosisSuccess(newList))
      yield call(updateTotalUtilizadoDetalleEnvasado)
    }
  } catch (error) {}
}
export function * watchDeleteSeleccionEnvasadoMultiDosis () {
  yield takeLatest(actionTypes.DELETE_SELECCION_ENVASADO_MULTI_DOSIS, deleteSeleccionEnvasadoMultiDosis)
}

export function * abrirModalEnvasadoMultiDosis ({row}) {
  try {
    let auth = yield select(state => state.auth)
    yield all([
      comboTipoDosisSaga()
    ])
    let initialValues = {
      idMaquina: '',
      tipoDosis: '',
      numeroDosis: 0,
      editable: true
    }
    if (row) {
      let comboMaquina = yield select(state => state.combos.comboMaquina)
      let idMaquina = ''
      let caducidad = false
      let etiqueta = false
      if (row.idMaquina) {
        idMaquina = comboMaquina.filter((maquina) => {
          if (maquina.value === row.idMaquina){
            return maquina
          }
        })
        // caducidad = row.fechaCaducidadEtiqueta
        // etiqueta = row.etiqueta
        caducidad = true
        etiqueta = true
      } else if ((comboMaquina[0] && comboMaquina.length === 1) && (row.idEnvasadoMultiDosis == null)){
        idMaquina = comboMaquina[0]
        caducidad = true
        etiqueta = true
      }
      let idTipoDosis = yield select(state => state.combos.comboTipoDosis)
      idTipoDosis = idTipoDosis.filter((dosis) => {
        if (dosis.value === row.idTipoDosis){
          return dosis
        }
      })

      initialValues.idEnvasadoMultiDosis = row.idEnvasadoMultiDosis
      initialValues.idMaquina = row.idMaquina ? idMaquina[0] : idMaquina
      initialValues.loteBolsas = row.loteBolsas
      initialValues.tipoDosis = row.idTipoDosis ? idTipoDosis[0] : ''
      initialValues.numeroDosis = row.numeroDosis
      initialValues.trazabilidad = row.trazabilidad
      initialValues.fechaCaducidadEtiqueta = caducidad
      initialValues.etiqueta = etiqueta
      initialValues.envasado = row.envasado
      initialValues.envasadoEliminado = row.envasadoEliminado
      initialValues.observaciones = row.observaciones
      initialValues.editable = row.editable
      initialValues.NumDosisEnvasadas = row.NumDosisEnvasadas

    } else {
      let comboMaquina = yield select(state => state.combos.comboMaquina)
      if (comboMaquina[0] && comboMaquina.length === 1) {
        initialValues.idMaquina = comboMaquina[0]
        initialValues.fechaCaducidadEtiqueta = true
        initialValues.etiqueta = true
      }
    }
    yield put(initialize('ModalEnvasadoMultiDosis', initialValues))
    yield put(abrirModalEnvasadoMultiDosisSuccess())

    if (row && auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD)) {
      yield put(comprobarStockTrazabilidadSuccess({[categoriasEscandallos.ENVASADO]: row.stockInsuficiente}))
      if (row.idTipoDosis) {
        yield put(openModalTrazabilidadMaterial({
          idTipoDosis: row.idTipoDosis,
          idCategoria: categoriasEscandallos.ENVASADO,
          idEnvasadoMultiDosis: row.idEnvasadoMultiDosis || null,
          ultimaModificacion: row.ultimaModificacion,
          openModal: false,
          showStockColumns: (!row.estadoInicialEnvasado || /^new/.test(row.idEnvasadoMultiDosis))
        }))
      }
    }
  } catch (error) {}
}
export function * watchAbrirModalEnvasadoMultiDosis () {
  yield takeLatest(actionTypes.ABRIR_MODAL_ENVASADO_MULTI_DOSIS, abrirModalEnvasadoMultiDosis)
}

export function * abrirModalEnvasadoMultiDosisDesdeListadoPrincipal ({idEnvasado, idEnvasadoMultiDosis}) {
  try {
    let callback = function * (row) {
      if (row.multiDosis.length > 0) {
        let multiDosisRow = row.multiDosis.find((dosis) => dosis.idEnvasadoMultiDosis === idEnvasadoMultiDosis)
        if (multiDosisRow) yield call(abrirModalEnvasadoMultiDosis, {row: multiDosisRow})
      }
    }
    yield call(abrirDetallesEnvasado, {idEnvasado, callback})
  } catch (error) {}
}
export function * watchAbrirModalEnvasadoMultiDosisDesdeListadoPrincipal () {
  yield takeLatest(actionTypes.ABRIR_MODAL_ENVASADO_MULTI_DOSIS_DESDE_LISTADO_PRINCIPAL, abrirModalEnvasadoMultiDosisDesdeListadoPrincipal)
}

export function * guardarModalEnvasadoMultiDosis ({values}) {
  try {
    const envasadoData = yield select(state => state.envasado.data)
    let newMultiDosisList = envasadoData.multiDosis.slice()
    let newDosis = {
      idEnvasadoMultiDosis: values.idEnvasadoMultiDosis || "new_" + (newMultiDosisList.length + 1),
      idEnvasado: envasadoData.idEnvasado,
      idMaquina: values.idMaquina ? values.idMaquina.value : null,
      nombreMaquina: values.idMaquina ? values.idMaquina.label : null,
      idTipoDosis: values.tipoDosis.value,
      tipoDosis: values.tipoDosis.label,
      volumenTipoDosis: values.tipoDosis.volumen,
      numeroDosis: values.numeroDosis,
      volumen: parseFloat(values.tipoDosis.volumen) * parseInt(values.numeroDosis, 10),
      trazabilidad: values.trazabilidad,
      fechaCaducidadEtiqueta: values.fechaCaducidadEtiqueta,
      etiqueta: values.etiqueta,
      envasado: values.envasado,
      envasadoEliminado: values.envasadoEliminado,
      observaciones: values.observaciones,
      editable: values.editable,
      guardarUltimaModificacion: true,
      loteBolsas: values.loteBolsas,
      NumDosisEnvasadas: values.NumDosisEnvasadas
    }

    console.log(values)
    console.log("guardarModalEnvasadoMultiDosis newDosis", newDosis)

    if (values.idEnvasadoMultiDosis) {
      newMultiDosisList = newMultiDosisList.map((dosis) => {
        if (dosis.idEnvasadoMultiDosis === values.idEnvasadoMultiDosis) {
          return {...newDosis, estadoInicialEnvasado: dosis.estadoInicialEnvasado}
        } else  {
          return dosis
        }
      })
    } else {
      newMultiDosisList.push(newDosis)
    }

    let totalUtilizado = 0
    if (newMultiDosisList && newMultiDosisList.length > 0) {
      newMultiDosisList.forEach((dosis) => {
        totalUtilizado += dosis.volumen
      })

      //Si aún hay volumen disponible, añadimos una nueva fila
      if(totalUtilizado < envasadoData.totalDisponible){
        let volumenTipoDosis = parseFloat(envasadoData.volumenTipoDosis)
        let volumenRestante = envasadoData.totalDisponible-totalUtilizado;
        let numeroDosisRestante = volumenRestante>volumenTipoDosis ? parseInt(volumenRestante/volumenTipoDosis) : "0";
        let newDosisRestante = {
          idEnvasadoMultiDosis: "new_" + (newMultiDosisList.length + 1),
          idEnvasado: envasadoData.idEnvasado,
          idMaquina: null,
          nombreMaquina: null,
          idTipoDosis: envasadoData.idTipoDosis,
          tipoDosis: envasadoData.nombreTipoDosis,
          volumenTipoDosis: volumenTipoDosis,
          numeroDosis: numeroDosisRestante,
          volumen: volumenTipoDosis * parseInt(numeroDosisRestante, 10),
          trazabilidad: null,
          fechaCaducidadEtiqueta: false,
          etiqueta: false,
          envasado: false,
          envasadoEliminado: false,
          editable: true,
          guardarUltimaModificacion: true
        }
        newMultiDosisList.push(newDosisRestante)
      }
    }

    yield put(guardarModalEnvasadoMultiDosisSuccess(newMultiDosisList))
    yield call(updateTotalUtilizadoDetalleEnvasado)
    yield put(resetUtilizarLotesPreferidosDelUsuarioSuccess())
  } catch (error) {}
}
export function * watchGuardarModalEnvasadoMultiDosis () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_ENVASADO_MULTI_DOSIS, guardarModalEnvasadoMultiDosis)
}

export function * racePrepararMaquinaEnvasado ({values}) {
  const controller = new AbortController();
  const signal = controller.signal;

  const {finished} = yield race({
    finished: call(prepararMaquinaEnvasado, {values, signal}),
    cancelled: take(actionTypes.CANCELAR_MAQUINA_ENVASADO)
  })

  if (!finished) {
    controller.abort();
    yield put(actualizarEstadoMaquinaEnvasado(''))
  }
}
export function * watchPrepararMaquinaEnvasado () {
  yield takeLatest(actionTypes.PREPARAR_MAQUINA_ENVASADO, racePrepararMaquinaEnvasado)
}

export function * prepararMaquinaEnvasado ({values, signal}) {
  try {
    yield put(actualizarEstadoMaquinaEnvasado(estadoMaquinaEnvasado['PROCESANDO_ENVASADO']))
    let state = yield select(state => state)

    const envasadoData = state.envasado.data
    let newMultiDosisList = envasadoData.multiDosis.slice()
    // Quitar marca de envir a envasar.
    newMultiDosisList = newMultiDosisList.map((dosis) => {
      return {...dosis, mandarAEnvasar: false}
    })
    let newDosis = {
      idEnvasadoMultiDosis: values.idEnvasadoMultiDosis || "new_" + (newMultiDosisList.length + 1),
      idEnvasado: envasadoData.idEnvasado,
      idMaquina: values.idMaquina ? values.idMaquina.value : null,
      nombreMaquina: values.idMaquina ? values.idMaquina.label : null,
      idTipoDosis: values.tipoDosis.value,
      tipoDosis: values.tipoDosis.label,
      volumenTipoDosis: values.tipoDosis.volumen,
      numeroDosis: values.numeroDosis,
      volumen: parseFloat(values.tipoDosis.volumen) * parseInt(values.numeroDosis, 10),
      trazabilidad: values.trazabilidad,
      fechaCaducidadEtiqueta: values.fechaCaducidadEtiqueta,
      etiqueta: values.etiqueta,
      envasado: values.envasado,
      envasadoEliminado: values.envasadoEliminado,
      observaciones: values.observaciones,
      editable: values.editable,
      mandarAEnvasar: true,
    }
    if (values.idEnvasadoMultiDosis) {
      newMultiDosisList = newMultiDosisList.map((dosis) => {
        if (dosis.idEnvasadoMultiDosis === values.idEnvasadoMultiDosis) {
          return {...newDosis, estadoInicialEnvasado: dosis.estadoInicialEnvasado}
        } else  {
          return dosis
        }
      })
    } else {
      newMultiDosisList.push(newDosis)
    }
    //yield put(actualizarEnvasadoMultiDosisSuccess(newMultiDosisList))

    state = yield select(state => state)
    let updatedValues = yield getFormValues('ModalEnvasado')(state)
    updatedValues = {...updatedValues, multiDosis: newMultiDosisList}

    let {maquinaPreparada, maquinaEnUso, idEnvasadoMultiDosisAEnvasar, conexionVPN, error, loteBolsas} = 
        yield call(prepararMaquinaEnvasadoService, updatedValues, state.auth.token, signal)
    let codigoEstadoMaquina
    let mensajeEstadoMaquina
    if (error) {
      codigoEstadoMaquina = error ? error.codigo : null
    } else if(maquinaEnUso) {
      codigoEstadoMaquina = estadoMaquinaEnvasado['EN_USO']
    } else if (maquinaPreparada) {
      codigoEstadoMaquina = estadoMaquinaEnvasado['PREPARADA']
    } else if (conexionVPN === false) {
      codigoEstadoMaquina = estadoMaquinaEnvasado['CONEXION_VPN_NO_ESTABLECIDA']
    } else  {
      codigoEstadoMaquina = estadoMaquinaEnvasado['NO_PREPARADA']
    }
    
    if (
      codigoEstadoMaquina === estadoMaquinaEnvasado['NO_PREPARADA'] ||
      codigoEstadoMaquina === estadoMaquinaEnvasado['CONEXION_VPN_NO_ESTABLECIDA'] ||
      codigoEstadoMaquina === estadoMaquinaEnvasado['EN_USO'] ||
      codigoEstadoMaquina === error.codigo
    ) {
      yield put(actualizarEstadoMaquinaEnvasado(codigoEstadoMaquina))
      mensajeEstadoMaquina = i18n.t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaEnvasado[codigoEstadoMaquina])
      yield call(yesNoModalSaga, {modalType: 'dynamicMessage', message: mensajeEstadoMaquina})
    } else {
      codigoEstadoMaquina = null
      yield put(change('ModalEnvasadoMultiDosis', 'envasado', true))
      if(typeof(loteBolsas) !== 'undefined'){
        yield put(change('ModalEnvasadoMultiDosis', 'loteBolsas', loteBolsas))
      }
      yield put(actualizarEstadoMaquinaEnvasado(codigoEstadoMaquina['ENVASANDO']))

      // while ((!codigoEstadoMaquina || codigoEstadoMaquina === estadoMaquinaEnvasado['ENVASANDO'] || codigoEstadoMaquina === estadoMaquinaEnvasado['EN_ESPERA_SER_INICIADA'])) {
      //   codigoEstadoMaquina = yield call(comprobarEstadoMaquinaEnvasadoService, values.idMaquina.value, idEnvasadoMultiDosisAEnvasar, signal)
      //   codigoEstadoMaquina = codigoEstadoMaquina.codigo ? codigoEstadoMaquina.codigo : ''
      //   yield put(actualizarEstadoMaquinaEnvasado(codigoEstadoMaquina))
      //
      //   mensajeEstadoMaquina = i18n.t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaEnvasado[codigoEstadoMaquina])
      //   if (codigoEstadoMaquina === estadoMaquinaEnvasado['FINALIZADO']) {
      //     yield call(yesNoModalSaga, {
      //       modalType: 'dynamicMessage',
      //       message: i18n.t('COMUN.ESTADO_MAQUINA.ENVASADO_COMPLETADO'),
      //       subMessage: mensajeEstadoMaquina
      //     })
      //     // Una vez completado el proceso sin errores se cerrara el formulario y se recargara el listado.
      //     yield put(cerrarModalEnvasadoMultiDosisSuccess())
      //     yield call(updateTotalUtilizadoDetalleEnvasado)
      //   } else if (codigoEstadoMaquina !== estadoMaquinaEnvasado['ENVASANDO'] && codigoEstadoMaquina !== estadoMaquinaEnvasado['EN_ESPERA_SER_INICIADA']) {
      //     yield call(yesNoModalSaga, {
      //       modalType: 'dynamicMessage',
      //       message: i18n.t('COMUN.ESTADO_MAQUINA.ENVASADO_NO_COMPLETADO'),
      //       subMessage: mensajeEstadoMaquina
      //     })
      //   }
      //   yield delay(1000);
      // }
    }

    let callback = function * (row) {
      if (row.multiDosis.length > 0) {
        let multiDosisRow = row.multiDosis.find((dosis) => dosis.idEnvasadoMultiDosis === newDosis.idEnvasadoMultiDosis)
        if (!multiDosisRow) {
          multiDosisRow = row.multiDosis.find((dosis) => {
            return !newMultiDosisList.some((newDosisList) => {
              return dosis.idEnvasadoMultiDosis === newDosisList.idEnvasadoMultiDosis
            })
          })
        }
        if (multiDosisRow) yield call(abrirModalEnvasadoMultiDosis, {row: multiDosisRow})
      }
    }

    yield call(abrirDetallesEnvasado, {idEnvasado: envasadoData.idEnvasado, callback: callback})
  } catch (error) {}
}

export function * cerrarModalEnvasadoMultiDosis () {
  try {
    yield put(cerrarModalEnvasadoMultiDosisSuccess({}))
    yield put(resetUtilizarLotesPreferidosDelUsuarioSuccess())
  } catch (error) {}
}
export function * watchCerrarModalEnvasadoMultiDosis () {
  yield takeLatest(actionTypes.CERRAR_MODAL_ENVASADO_MULTI_DOSIS, cerrarModalEnvasadoMultiDosis)
}

export function * updateTotalUtilizadoDetalleEnvasado () {
  try {
    const envasadoData = yield select(state => state.envasado.data)
    let totalUtilizado = 0
    if (envasadoData.multiDosis && envasadoData.multiDosis.length > 0) {
      envasadoData.multiDosis.forEach((dosis) => {
        // TODO Eliminar si no es necesario. Al cargar el listado ya se almacena el calculo del volumenDosis X numDosis.
        // dosis.volumen = parseFloat(dosis.volumenTipoDosis) * parseInt(dosis.numeroDosis, 10)
        totalUtilizado += dosis.volumen
      })
    }
    yield put(updateTotalUtilizadoDetalleEnvasadoSuccess(totalUtilizado))
  } catch (error) {}
}
export function * watchUpdateTotalUtilizadoDetalleEnvasado () {
  yield takeLatest(actionTypes.UPDATE_TOTAL_UTILIZADO_DETALLE_ENVASADO, updateTotalUtilizadoDetalleEnvasado)
}

export function* lecturaRFIDEnvasado() {
  try {
    let auth = yield select(state => state.auth)

    // datos de configuracion del dispositivo
    const list = yield call(getFiltrarConectividad, null, auth.token)
    let COMPort = '8'
    if(list.length > 0){
      list.map((li, i) => {
        if(li.idTipoMaquina == 5){
          COMPort = li.IP
        }
      })
    }

     // recuperamos lectura
    let response = yield call(printronixService, null, "rfid_caen?port="+COMPort) 
    let idDevolver = null
    let tipo = null

    if(response && response.MyTags){
      response.MyTags.forEach(tag => {
        let id = tag.substring(0,10)
        let type = tag.substring(10,12)
        if(idDevolver == null && (type=='FA' || type=='FC')) {
            idDevolver = parseInt(id)
            tipo = type
        }
      });
    }


    let idEnvasado=null;
    if(tipo && idDevolver){
      let listEnvasados = yield select(state => state.envasado.list)

      if(listEnvasados){
        listEnvasados.forEach(item => {
        if(item.envasado == false){     
          
            if(tipo == 'FA' && item.idAnalisis!=null && item.idAnalisis == idDevolver) {
              idEnvasado = item.idEnvasado
            }
            else if(tipo=='FC' && item.idPool!=null && item.idPool == idDevolver) {
              idEnvasado = item.idEnvasado
            }
          }
        })
      }

      console.log("id envasado", idEnvasado)

      if(idEnvasado!=null) {
        yield put(abrirDetallesEnvasadoAction(idEnvasado))
      }
    }

  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchlecturaRFIDEnvasado() {
  yield takeLatest(actionTypes.LECTURA_RFID_ENVASADO, lecturaRFIDEnvasado)
}

export function* deleteSeleccionDosis({ list }) {  
  try {
    yield put(openSimpleModal('onDeleteDosis'))
    const confirmed = yield call(yesNoModalSaga, { modalType: 'onDeleteDosis' })    
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let filtros = yield select(state => state.envasado.filtros)
      const { eliminado } = yield call(deleteSeleccionDosisService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      
      yield put(initialize('FiltrosEnvasado'))
      const inicializaTabla = yield call(getFiltrarEnvasado, filtros, auth.token)
      yield put(initialize('simpleTable'))
      yield put(fetchEnvasadoSuccess(inicializaTabla))
      yield put(fetchFiltrarEnvasadoSuccess(filtros))

      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))     
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function* watchDeleteSeleccionDosis() {
  yield takeLatest(actionTypes.DELETE_SELECCION_DOSIS, deleteSeleccionDosis)
}