import actionTypes from '../../constants/actions/importarVerracosExcel/importarVerracosExcel'

export function initialState () {
  return {
    showModalImportarVerracosExcel: false,
    archivoExcel: [],
    listImportarVerracosExcel: []
  }
}

export function abrirModalImportarVerracosExcel (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalImportarVerracosExcel: true
  }
}

export function cerrarModalImportarVerracosExcel (state, {listCopiaSeguridad}) {
  return {
    ...state,
    showModalImportarVerracosExcel: false,
    archivoExcel: [],
    listImportarVerracosExcel: []
  }
}

export function subirArchivosImportarVerracosExcel (state, {archivoExcel}) {
  return {
    ...state,
    archivoExcel: archivoExcel
  }
}

export function submitImportarVerracosExcelSuccess (state, {listImportarVerracosExcel}) {
  return {
    ...state,
    listImportarVerracosExcel: listImportarVerracosExcel
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_IMPORTAR_VERRACOS_EXCEL:
      return abrirModalImportarVerracosExcel(state, action)
     case actionTypes.CERRAR_MODAL_IMPORTAR_VERRACOS_EXCEL:
      return cerrarModalImportarVerracosExcel(state, action)
     case actionTypes.SUBIR_ARCHIVOS_IMPORTAR_VERRACOS_EXCEL:
      return subirArchivosImportarVerracosExcel(state, action)
     case actionTypes.SUBMIT_IMPORTAR_VERRACOS_EXCEL_SUCCESS:
      return submitImportarVerracosExcelSuccess(state, action)
    default:
      return state
  }
}
