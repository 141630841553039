import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { Warning, Clear , Check } from '@material-ui/icons'
import Loader from '../comun/loader/Loader'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import ModalTrazabilidadMaterialPage from '../../containers/trazabilidadMaterial/ModalTrazabilidadMaterialPage'
import { date_formatter } from '../../util/formatFunctions'
import { categorias as categoriasEscandallos } from '../../constants/escandallo'
import { estadoMaquinaDilucion, translateEstadoMaquinaDilucion } from '../../constants/maquinas'
import { permisos as permisosConstant } from '../../constants/permisos'
import { modulos as modulosConstant } from '../../constants/modulos'
import InputText from '../comun/form/InputText'

class ModalDilucion extends Component {  
  constructor(props) {
    super()
    this.state = {
      dosis: null,
      diluido: false,
      dilucionEliminada: false,
      maquinaDilucionSeleccionada: false,
      idMaquina:null
    }
    this.intercambiarCheckDiluido = this.intercambiarCheckDiluido.bind(this)
    this.handleOpenModalTrazabilidadMaterial = this.handleOpenModalTrazabilidadMaterial.bind(this)
    this.prepararMaquinaDilucion = this.prepararMaquinaDilucion.bind(this)
    this.cancelarMaquinaDilucion = this.cancelarMaquinaDilucion.bind(this)
  }

  componentDidMount() {
    this.props.actions.comboMaquina(2)
    this.props.actions.comboOperarioPredeterminado()
    this.props.actions.fetchComboTipoDiluyente()
    
    if (this.props.data && this.props.data.diluido) {
      this.setState({ diluido: this.props.data.diluido })
    }
    if (this.props.data && this.props.data.dilucionEliminada) {
      this.setState({ dilucionEliminada: this.props.data.dilucionEliminada })
    }
  }

  componentDidUpdate(prevProps) {    
    if (this.props.data.idMaquina != prevProps.data.idMaquina) {
      this.setState({idMaquina:this.props.data.idMaquina})
    }
    if (this.props.formModalDilucion && this.props.formModalDilucion.diluido !== this.state.diluido) {
      this.setState({ diluido: this.props.formModalDilucion.diluido })
    }
    if (this.props.showModalDilucion && this.props.showModalDilucion !== prevProps.showModalDilucion) {
      if (this.props.data && this.props.data.diluido !== this.state.diluido) {
        this.setState({ diluido: this.props.data.diluido })
      }
      if (this.props.data && this.props.data.dilucionEliminada !== this.state.dilucionEliminada) {
        this.setState({ dilucionEliminada: this.props.data.dilucionEliminada })
      }
    } else if (!this.props.showModalDilucion && this.props.showModalDilucion !== prevProps.showModalDilucion) {
      this.setState({ diluido: false, dilucionEliminada: false })
    }
  }

  setMaquinaDilucion(value) {
    this.setState({ idMaquina: value });
  }
  editarDilucion(values) {
    values.nombreDiluyente = values.idTipoDiluyente !== null ? values.idTipoDiluyente.label : null
    values.nombreMaquina = values.idOperario !== null ? values.idOperario.label : null
    values.nombreOperario = values.idMaquina !== null ? values.idMaquina.label : null    
    //console.log(values)
    this.props.actions.editarDilucion(values)
  }

  intercambiarCheckDilucionEliminada(value) {
    let newState = {
      dilucionEliminada: value,
      diluido: value
    }
    this.setState(newState)
  }

  intercambiarCheckDiluido(value) {
    this.setState({ diluido: value })
  }

  checkDosis(value) {
    let dosis = value
    this.setState({ dosis: dosis })
    //console.log("checkDosis " + dosis + " - "+this.props.data.numeroDosisMax)
    let ve = (dosis / this.props.data.numeroDosisMax) * this.props.data.volumenExtraccion
    this.props.change('volumenExtraccion', Math.round(ve))
    let vd = (dosis / this.props.data.numeroDosisMax) * this.props.data.volumenDiluyente
    this.props.change('volumenDiluyente', Math.round(vd))
    //Actualizamos trazabilidad
    this.updateTrazabilidad()
  }

  updateTrazabilidad() {

    this.props.actions.actualizarTrazabilidadMaterial({
      idTipoDosis: this.props.data.idTipoDosis,
      idCategoria: categoriasEscandallos.DILUCION,
      idAnalisis: this.props.data.idAnalisis,
      ultimaModificacion: this.props.data.ultimaModificacion,
      disabled: this.props.data.envasado,
      showStockColumns: !this.props.data.diluido,
      utilizarLotesPreferidosDelUsuario: this.props.utilizarLotesPreferidosDelUsuario,
      dosis: this.state.dosis,
      dosisMax: this.props.data.numeroDosisMax
    })
  }

  handleOpenModalTrazabilidadMaterial() {
    if (this.props.data.idTipoDosis) {
      this.props.actions.openModalTrazabilidadMaterial({
        idTipoDosis: this.props.data.idTipoDosis,
        idCategoria: categoriasEscandallos.DILUCION,
        idAnalisis: this.props.data.idAnalisis,
        ultimaModificacion: this.props.data.ultimaModificacion,
        disabled: this.props.data.envasado,
        showStockColumns: !this.props.data.diluido,
        utilizarLotesPreferidosDelUsuario: this.props.utilizarLotesPreferidosDelUsuario,
        dosis: this.state.dosis,
        dosisMax: this.props.data.numeroDosisMax
      })
    }
  }

  prepararMaquinaDilucion(values) {
    values.nombreDiluyente = values.idTipoDiluyente !== null ? values.idTipoDiluyente.label : null
    values.nombreMaquina = values.idMaquina !== null ? values.idMaquina.label : null
    values.nombreOperario = values.idOperario !== null ? values.idOperario.label : null

    if (!values.idTipoDiluyente || !values.idTipoDiluyente.value) {
      this.props.actions.yesNoModal('diluyenteRequerido')
    } else if (!values.idMaquina || !values.idMaquina.value) {
      this.props.actions.yesNoModal('maquinaRequerida')
    } else {
      this.props.actions.prepararMaquinaDilucion(values)
    }
  }

  cancelarMaquinaDilucion() {
    this.props.actions.cancelarMaquinaDilucion()
  }

  render() {    
    const {
      t, handleSubmit, showModalDilucion, list, data: { numeroDosis, envasado, stockInsuficiente, idDilucion, codigo, codVerraco, fechaExtraccion, idMaquina }, codigoEstadoMaquinaDilucion,
      actions: { abrirDetalles, cerrarDetalles }, combos: { comboMaquina, comboOperarioPredeterminado, comboTipoDiluyente }, auth: { modulos },
      auth: { formaFechaHora }
    } = this.props    

    const diluyendo = false
    const hayDosisParaDiluir = numeroDosis >= 0;
    // const diluyendo = ( codigoEstadoMaquinaDilucion !== '' &&
    //   codigoEstadoMaquinaDilucion !== estadoMaquinaDilucion['EN_ESPERA_RETIRAR_RECIPIENTE'] &&
    //   codigoEstadoMaquinaDilucion !== estadoMaquinaDilucion['FINALIZADO'] &&
    //   codigoEstadoMaquinaDilucion !== estadoMaquinaDilucion['NO_PREPARADA']
    // )
    return (
      <Modal show={showModalDilucion} onHide={cerrarDetalles} aria-labelledby="contained-modal-title-lg" backdrop="static">
        <form className="form-dilucion" onSubmit={handleSubmit(this.editarDilucion.bind(this))}>
          <Modal.Header closeButton={!diluyendo}>
            <Modal.Title>
              {t('EXTRACCIONES.DILUCION.MODAL.REALIZAR_DILUCION')}
            </Modal.Title>
            {idDilucion && !diluyendo && (
              <ButtonChangeRecord list={list} idElemento="idDilucion" currentId={idDilucion} getNextRecord={abrirDetalles} />
            )}
          </Modal.Header>
          <Modal.Body>
            <ModalTrazabilidadMaterialPage />
            <Row>
              <Col sm={12}><p><b>{t('EXTRACCIONES.DILUCION.MODAL.EXTRACCION') + ': ' + codigo + ' - ' + date_formatter(fechaExtraccion, formaFechaHora, false) + ' - ' + codVerraco}</b></p></Col>
              <div className="clearfix"></div>
              <Field
                colSm={4}
                id="numeroDosis"
                name="numeroDosis"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.NUMERO_DOSIS')}
                component={InputNumerical}
                numDecimales={0}
                disabled={this.props.data.diluido}
                minValue={0}
                maxValue={parseInt(this.props.data.numeroDosisMax)}
                onInputChange={(value) => this.checkDosis(value)}
              />
              <Field
                colSm={4}
                id="volumenExtraccion"
                name="volumenExtraccion"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.VOL_EYACULADO')}
                component={InputNumerical}
                numDecimales={0}
                disabled={true}
              />
              <Field
                colSm={4}
                id="volumenDiluyente"
                name="volumenDiluyente"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.VOL_DILUYENTE')}
                component={InputNumerical}
                numDecimales={0}
                disabled={true}
              />
            </Row>
            <Row>
              <Field
                colSm={8}
                id="idTipoDiluyente"
                name="idTipoDiluyente"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.TIPO_DILUYENTE')}
                component={InputSelect}
                options={comboTipoDiluyente}
                valueKey="value"
                labelKey="label"
                customClass='select-tipo-diluyente'
                isClearable={true}
                disabled={diluyendo || envasado || this.state.dilucionEliminada}
              />
            </Row>
            <Row>
              <Col sm={8}>
                <p>{t('EXTRACCIONES.DILUCION.MODAL.AVISO_DILUYENTE')}</p>
              </Col>
            </Row>
            <Row style={{display: "flex", alignItems: "center" }}>
              <Field
                colSm={5}
                id="LecturaRefractometro"
                name="LecturaRefractometro"
                controlLabel={t('ANALISIS_EXTRACCIONES.DILUCION.FORM.REFRACTOMETRO')}
                component={InputNumerical}
                style={{ Margin:"0" }}
              />
              <Button 
                type="button"
                className='btn btn-primary'
                style={{ visibility:'collapse' }}
              >{t('ANALISIS_EXTRACCIONES.DILUCION.FORM.OBTENER')}</Button>
              <Col sm={2}>
              {
              this.props.formModalDilucion?.LecturaRefractometro != undefined &&
              this.props.formModalDilucion.idTipoDiluyente.MinGradosBrix != undefined && 
              this.props.formModalDilucion.idTipoDiluyente.MaxGradosBrix != undefined && 
              parseFloat(this.props.formModalDilucion.LecturaRefractometro) >= parseFloat(this.props.formModalDilucion.idTipoDiluyente.MinGradosBrix) &&
              parseFloat(this.props.formModalDilucion.LecturaRefractometro) <= parseFloat(this.props.formModalDilucion.idTipoDiluyente.MaxGradosBrix) ? (
                <Check style={{ color: 'rgb(0, 255, 0)' }}/>
              ) : 
              this.props.formModalDilucion?.LecturaRefractometro != undefined &&
              this.props.formModalDilucion?.LecturaRefractometro != '' && 
              this.props.formModalDilucion.idTipoDiluyente.MinGradosBrix != undefined && 
              this.props.formModalDilucion.idTipoDiluyente.MaxGradosBrix != undefined ? (
              <Clear className="text-danger"/>
              ) : 
              this.props.formModalDilucion?.LecturaRefractometro != undefined &&
              this.props.data.MinRefractometro != undefined && 
              this.props.data.MaxRefractometro != undefined && 
              parseFloat(this.props.formModalDilucion.LecturaRefractometro) >= parseFloat(this.props.data.MinRefractometro) &&
              parseFloat(this.props.formModalDilucion.LecturaRefractometro) <= parseFloat(this.props.data.MaxRefractometro) ? (
                <Check style={{ color: 'rgb(0, 255, 0)' }}/>
              ) :
              this.props.formModalDilucion?.LecturaRefractometro != undefined &&
              this.props.data.MinRefractometro != undefined && 
              this.props.data.MaxRefractometro != undefined ? (
                <Clear className="text-danger"/>
              ) :
              (
              <Clear style={{ visibility:'collapse' }}/>
              )}
              </Col>
              <Field
                colSm={5}
                id="Temperatura"
                name="Temperatura"
                controlLabel={t('ANALISIS_EXTRACCIONES.DILUCION.FORM.TEMPERATURA')}
                component={InputNumerical}
                numDecimales={0}
              />
            </Row>
            <Row>
              <Field
                colSm={6}
                id="idMaquina"
                name="idMaquina"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.MAQUINA')}
                component={InputSelect}
                options={comboMaquina}
                valueKey="value"
                labelKey="label"
                customClass='select-maquina'
                isClearable={true}
                onInputChange={(value) => this.setMaquinaDilucion(value)}
                disabled={diluyendo || envasado || this.state.dilucionEliminada}
              />
              <Field
                colSm={6}
                id="idOperario"
                name="idOperario"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.OPERARIO')}
                component={InputSelect}
                options={comboOperarioPredeterminado}
                valueKey="value"
                labelKey="label"
                customClass='select-operario'
                isClearable={false}
                disabled={diluyendo || envasado || this.state.dilucionEliminada}
              />
            </Row>
            <Row>
              <Field
                colSm={6}
                id="LoteDilucion"
                name="LoteDilucion"
                controlLabel={t('ANALISIS_EXTRACCIONES.DILUCION.FORM.LOTE_DILUCION')}
                component={InputText}
                numDecimales={0}
              />
            </Row>
            <Row>
              <Field
                id="diluido"
                name="diluido"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.DILUIDO')}
                disabled={diluyendo || envasado || !hayDosisParaDiluir || this.state.dilucionEliminada}
                onInputChange={(value) => this.intercambiarCheckDiluido(value)}
              />
            </Row>
            <Row>
              <Field
                id="dilucionEliminada"
                name="dilucionEliminada"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.DILUCION_ELIMINADA')}
                disabled={diluyendo || envasado}
                onInputChange={(value) => this.intercambiarCheckDilucionEliminada(value)}
              />
            </Row>
              <div className="clearfix"></div>
            <Row>
              <Field
                colSm={12}
                id="observacionesDilucion"
                name="observacionesDilucion"
                controlLabel={t('EXTRACCIONES.DILUCION.MODAL.OBSERVACIONES')}
                component={InputTextArea}
                disabled={diluyendo}
              />
            </Row>
              <div className="clearfix"></div>
            <Row>
              {this.state.dilucionEliminada && (
                <Field
                  colSm={12}
                  id="observacionesDilucionEliminada"
                  name="observacionesDilucionEliminada"
                  controlLabel={t('EXTRACCIONES.DILUCION.MODAL.OBSERVACIONES_ELIMINADO')}
                  component={InputTextArea}
                  disabled={diluyendo || envasado}
                />
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="row-flex">
              <div className="col-flex text-left bold">
                {diluyendo && (<Loader />)}
                {codigoEstadoMaquinaDilucion ? t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaDilucion[codigoEstadoMaquinaDilucion]) : ''}
              </div>
              <Col sm={diluyendo ? 8 : 7}>
                {modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                  <Button
                    type="button"
                    className={'btn btn-white ' + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.DILUCION] < 2) || diluyendo || this.state.dilucionEliminada ? ' disabled' : '')}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.DILUCION] > 1) && !diluyendo && !this.state.dilucionEliminada ? this.handleOpenModalTrazabilidadMaterial : null}
                  >
                    {stockInsuficiente && (<span className='help-block white'><Warning /></span>)} {t('EXTRACCIONES.DILUCION.MODAL.TRAZABILIDAD')}
                  </Button>
                )}
                <Button
                  type="button"
                  className={'btn btn-primary' + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.DILUCION] < 2) || this.state.diluido || this.state.dilucionEliminada || envasado || !hayDosisParaDiluir || !this.state.idMaquina ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.DILUCION] > 1) && !this.state.diluido && !this.state.dilucionEliminada && !envasado && hayDosisParaDiluir ? (!diluyendo ? handleSubmit(this.prepararMaquinaDilucion) : this.cancelarMaquinaDilucion) : null}
                >
                  {diluyendo ? t('EXTRACCIONES.DILUCION.MODAL.PARAR_DILUCION') : t('EXTRACCIONES.DILUCION.MODAL.DILUIR')}
                </Button>
                <Button
                  type="submit"
                  className={'btn btn-primary' + (this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.DILUCION] < 2 ? ' disabled' : '')}
                  disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.DILUCION] < 2) || diluyendo || envasado}
                >{t('EXTRACCIONES.DILUCION.MODAL.GUARDAR')}</Button>
                <Button type="button" className="btn btn-white" disabled={diluyendo} onClick={cerrarDetalles}>{t('EXTRACCIONES.DILUCION.MODAL.CANCELAR')}</Button>
              </Col>
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalDilucion'
})(ModalDilucion))