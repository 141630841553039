import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import {downloadDocument} from '../../util/util'
import actionTypes from '../../constants/actions/importarVerracosExcel/importarVerracosExcel'
import {submitImportarVerracosExcelSuccess} from '../../actions/importarVerracosExcel/importarVerracosExcel'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getImportarVerracosExcel from '../../services/importarVerracosExcel/getImportarVerracosExcel'
import getDescargarPlantillaExcelVerracosExcel from '../../services/importarVerracosExcel/getDescargarPlantillaExcelVerracosExcel'

export function * submitImportarVerracosExcel ({value}) {
  try {
    let auth = yield select(state => state.auth)
    const listCargaIndex = yield call(getImportarVerracosExcel, value, auth.token)    
    yield put(submitImportarVerracosExcelSuccess(listCargaIndex))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitImportarVerracosExcel () {
  yield takeLatest(actionTypes.SUBMIT_IMPORTAR_VERRACOS_EXCEL, submitImportarVerracosExcel)
}

export function * descargarPlantillaExcelVerracosExcel () {
  try {
    const archivoExcel = yield call(getDescargarPlantillaExcelVerracosExcel, null)
    let href = 'data:application/csv;base64,' + encodeURIComponent(archivoExcel.xls)
    let name = i18n.t('IMPORTAR_VERRACOS_EXCEL.EJEMPLO_EXCEL_VERRACOS') + ".xls"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDescargarPlantillaExcelVerracosExcel () {
  yield takeLatest(actionTypes.DESCARGAR_PLANTILLA_EXCEL_VERRACOS_EXCEL, descargarPlantillaExcelVerracosExcel)
}