import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ImportarVerracosExcel from '../../components/importarVerracosExcel/ImportarVerracosExcel'
import {submitImportarVerracosExcel, cerrarModalImportarVerracosExcel, subirArchivosImportarVerracosExcel, abrirModalImportarVerracosExcel, descargarPlantillaExcelVerracosExcel} from '../../actions/importarVerracosExcel/importarVerracosExcel'

export function mapStateToProps (state) {
  return {
    ...state.importarVerracosExcel,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitImportarVerracosExcel,
      cerrarModalImportarVerracosExcel,
      subirArchivosImportarVerracosExcel,
      abrirModalImportarVerracosExcel,
      descargarPlantillaExcelVerracosExcel
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImportarVerracosExcel))